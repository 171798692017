<template>
  <div class="project-images">
    <div class="row" v-if="type === 'large'">
      <div class="col" v-if="images.length===1">
        <img v-bind:src="images[0].uncroppedImage.srcWebp" v-bind:srcset="images[0].uncroppedImage.srcsetWebp" alt="image" class="img-fluid" />
      </div>
      <div class="col" v-if="images.length>1">
        <project-image-gallery
          v-bind:images="images">
        </project-image-gallery>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col col-md-12 col-lg-9" v-if="images.length===1">
        <img v-bind:src="images[0].uncroppedImage.srcWebp" v-bind:srcset="images[0].uncroppedImage.srcsetWebp" alt="image" class="img-fluid" />
      </div>
      <div class="col col-md-12 col-lg-9" v-if="images.length>1">
        <project-image-gallery
          v-bind:images="images">
        </project-image-gallery>
      </div>
    </div>
  </div>
</template>
<script>
  const ProjectImageGallery = () => import(/* webpackChunkName: "project-image-gallery" */ '@/components/ProjectImageGallery.vue')
  export default {
    name: 'ProjectImage',
      props: {
      type: String,
      images: Array
    },
    components: {
      ProjectImageGallery
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "src/assets/scss/custom.scss";
  .project-images{
    padding-top: 30px;
    padding-bottom: 30px;
    img{
      width: 100%;
    }
  }
</style>
